function NotFound() {

    return (

        <div className="col-lg-6 mb-4">
            <p>Contenido no encontrado!</p>
        </div>

    )

}

export default NotFound;